//**********************************//
//   Default FormBuilderV2 Styles	//
//**********************************//

.intro_in_form {
	padding: 110px 18px;

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    h1 {
        color: $color_blk_thick;
        font-family: $font-family-secondary;
        font-size: 37px;
        line-height: 47px;
        font-weight: 500;
        padding: 0 0 30px 0; 

        @media (max-width: $screen-sm) {
            padding: 0 0 20px 0;
        }

        br {
           @media (max-width: $screen-xs) {
                display: none;
            }
        }
    }

    p {
        padding: 0 0 20px 0;
        @media (max-width: $screen-sm) {
            padding: 0 0 15px 0;
        }
    }
}

.formbuilderv2_form {

	span.form_field_not_required {
		display: none;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
	}

	ul li {
		background-image: none;
		padding-left: 0;
	}

	.form_field_title {
		font-family: $font-family-base;
		line-height: 24px;
    	font-weight: 500;
		color: $brand-primary;
		padding-bottom: 4px;
	}

	.form_title {
		display: none;
	}

	img.ui-datepicker-trigger {
		padding-top: 4px;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 7px;
	}

	.form_field_element input[type="text"],
	.form_field_element input[type="email"],
	.form_field_element select,
	.form_field_element textarea {
		width: 90%;
		padding: 5px 10px;
		height: 40px;
		font-size: 16px;
		border: none;
	}

	.form_field_element textarea {
		height: 150px;
	}

	.form_field_hint {
		font-size: 70%;
		width: 90%;
	}

	.form_field_element input.form_field_half {
		width: 44%;
		height: 40px;
		border: none;
		font-size: 16px;
	}

	.form_field_element input.form_field_half:last-child {
		float: right;
		margin-right: 10%;
	}

	.formbuilderv2_form_field {
		@include multi-column(12,6);
		padding: 10px 0px;

		@media only screen and (max-width: $screen-sm) {
			padding-left: 5%;
		}
	}

	.form_submit {
		@include multi-column(12);

		input[type="submit"] {
			@extend .btn_sigup;
			float: right;
			margin-right: 4%;
			margin-top: 0;			
		}		
	}
}

//***************************************//
//   Error Classes FormBuilderV2 Styles  //
//***************************************//

.tip-error,
.tip-darkgray {
	color: #fff;
	padding: 15px;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,.06);
	&:before {
		position: absolute;
		content: "";
		width: 0; 
		height: 0;
		top: 15px;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
	}
}

.tip-error {
	background-color: #d12c0a;
	&:before {
		border-right: 10px solid #d12c0a;
		right: -10px;
		transform: rotate(180deg);
	}
}
.tip-darkgray {
	background-color: $gray-dark;
	width: 250px;
	&:before {
		border-right:10px solid $gray-dark; 
		left: -10px;
	}
}