/* MP3 */

/* Overview */
.resize_map_img {
    z-index: 1;
    
    @media(max-width: 599px) {
        max-width: 100%;
        height: auto;
    }
}

.map_sec_in {
    position: relative;
    text-align: center;
    &:after {
        content: "";
        width: 50%;
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        background: #e7f0fd;
    }

    figure {
        text-align: center;

        div {
            margin: 0 auto;
        }
    }
}

.prop {
    display: block;
}

.pro_in {
    padding: 6px 18px 0 18px;
    ul {
        li {
            @media (max-width: $screen-xs) {
                width: 100%;
                display: block;
                float: none;
            }
        }
    }
}

.filter_sec {
    display: block;
}

.filter_sec_in {
    padding: 64px 0 64px 0;

    @media (max-width: $screen-md){
        padding-left: 18px;
        padding-right: 18px;
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .txt_prime {
        padding: 0 0 24px 0;
        display: inline-block;
    }

    ul {
        margin: 0 -4px;
        list-style: none;
        padding: 0;

        li {
            list-style: none;
            padding: 4px;

            @media (max-width: $screen-xs) {
                width: 100%;
                display: block;
                float: none;
            }
            .btn_sigup {
                width: 100%;
            }
        }

        @media (max-width: $screen-xs) {
            padding-left: 0;
        }
    } 

    .btn-flt-r {
        padding-right: 4px;

        @media (max-width: $screen-sm) {
            .btn_sigup {
                padding: 11px;
            }
        }
    }

    .btn-flt-l {
        padding-left: 4px;

        @media (max-width: $screen-sm) {
            .btn_sigup {
                padding: 11px;
            }
        }
    }

    @media (max-width: $screen-xs) {
        .btn-flt-r, .btn-flt-l {
            padding-left: 0;
            padding-right: 0;
            margin: 0 15px 5px 15px;
        }
    }
}

.prop_cnt_fig {
    position: relative;

    .prop_cnt_img {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 350px;
    }
} 

.prop_cnt {
    @media (max-width: $screen-xs) {
        height: inherit !important;

        .details_info {
            padding: 28px 15px 10px;
        }
    }

    .txt_prime {
        color: #616161;
    }

    h5 {
        padding: 0;
    }

    .h5_sec {
        line-height: 36px;
    }

    &:hover {
        .feature {
            opacity: 0;
            visibility: hidden;
        }

        .prop_cnt_hov {
            opacity: 1;
            visibility: visible;

            span {
                color: $color_white;
            }
        }
    }
}

.feature {
    padding: 14px 20px;
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0px;
    background: rgba(0, 1, 253, 0.8);
    font-family: $font-family-base;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    color: $color_white;
    .fa {
        margin-right: 10px;
    }
}

.feature-hidden {
    display: none;
}

.yes {
    display: inline-block;
}

.issold {
    padding: 14px 20px;
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0px;
    background: rgba(76, 195, 11, 0.8);
    font-family: $font-family-base;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    color: $color_white;
    .fa {
        margin-right: 10px;
    }
}

.Sold {
    display: inline-block;
}

.prop_cnt_hov {
    padding: 5px;
    background: rgba(0, 0, 254, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    @include transition_effect;

    span {
        color: $color_white;
        padding: 0 0 25px 0;
        display: inline-block;
    }
}


/* Property Detail */

.back_in {
    padding: 38px 0 38px 0;
    @media (max-width: $screen-md) {
        padding-left: 18px;
        padding-right: 18px;
    }
    .txt_sec {
        @media (max-width: $screen-xs) {
            font-size: 12px;
        }
    }
    .social_icons {
        padding: 0 0 0 0;
        li {
            padding: 0 0 0 25px;
            @media (max-width: $screen-xs) {
                padding: 0 0 0 10px;
            }
        }
    }
}

.gallery_grid {
    display: block;
}

.gallery_grid_in {
    padding: 102px 0 90px 0;
    overflow: hidden;

    @media (max-width: $screen-md) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.galpro {
    margin: 0 -15px;

    .property-slider {
  
        .property-slider-item {
            border: none;
            min-height: 425px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .slick-dots {
            margin: 15px 0;
            
            li {
                width: auto;

                &:nth-child(1), &:nth-child(5) {
                    padding: 0 10px 0 0;
                }
              
                &.active {
                    border-color: $brand-primary;
                }

                img {
                    border: none;
                }

                @media (max-width: $screen-xs) {
                    &:nth-child(3), &:nth-child(5), &:nth-child(7) {
                        padding: 0 10px 0 0;
                    }
                }
            }


        } 
    }

}

.gallery_large {
    figure {
        padding: 0 0 6px 0;
    }
}

.gallery_thumbnails {
    margin: 0 -6px;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
        padding: 6px;
    }
}

.property_blk {
    padding-top: 30px;

    @media (max-width: $screen-xs) {
        padding-top: 55px;
    }

    ul {
        padding: 20px 0 28px 0;
        margin: 0 -30px;
        list-style: none;

        @media (max-width: $screen-md) {
            margin: 0;
        }

        li {
            list-style: none;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: center;

            @media (max-width: $screen-xs) {
                width: 50%;
            }

            figure {
                padding: 0 0 15px 0;

                img {
                    margin: 0 auto;
                }
            }

            strong {
                @media (max-width: $screen-xs) {
                    font-size: 15px;
                }
            }
        }
    }
    p {
        font-style: italic;
    }
}

.txt_link {
    color: $color_blue2;
    &:hover,
    &:active {
        color: $color_blue2;
    }
} 

.property_mid {
    padding: 0 0 0 15px;
    max-width: 400px;
    margin: 0 auto;

    @media (max-width: $screen-xs) {
        text-align: center;
        padding: 0;
    }

    .txt_prime {
        display: block;
        height: 30px;
    } 
}

.property_det {}

.property_det_in {
    padding: 100px 0 105px 0;

    @media (max-width: $screen-md) {
        padding-left: 18px;
        padding-right: 18px;
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media (max-width: $screen-xs) {
        max-width: 670px;
        margin: 0 auto;
    }

    ul {
        margin: 0 -15px;
        padding: 0;
        list-style: none;

        li {
            list-style: none;
        }
    }
}

.js-gmap {
    height: 500px;

    @media (min-width: 320px) {
        height: 350px;
    }

    @media (min-width: $screen-sm) {
        height: 400px;
    }

    @media (min-width: $screen-md) {
        height: 500px;
    }
}

.property_info {

    @media (max-width: $screen-xs) {
        max-width: 670px;
    }

    h2 {
        padding: 0 0 45px 0;
        @media (max-width: $screen-sm) {
            padding: 0 0 20px 0;
        }
    }

    p {
        padding: 0 0 28px 0;
    }

    .txt_lst {

        padding-left: 35px;
        padding-bottom: 57px;
        @media (max-width: $screen-xs) {
            padding-left: 15px;
        }

        li {
            padding-bottom: 5px;
            line-height: 25px;
            background-position: 7px 10px;
        }
    }

    .accordion-content {

        ul {

            margin: 20px 0 0 20px;

            li {
                list-style: disc;
                margin: 0 0 10px;
            }
        }
    }
}

.acc_blk {
    h5 {
        a {
            padding: 30px 0;
            display: inline-block;
            border-top: 1px solid #e7e7e7;
            border-bottom: 1px solid #e7e7e7;
            display: block;
            position: relative;
            &:after {
                content: "\f067";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                position: absolute;
                top: 30px;
                right: 20px;
                font-size: 16px;
            }
        }
    }
}

.related {
    display: block;
}

.related_in {
    padding: 87px 18px 29px 18px;

    @media (max-width: $screen-sm) {
        padding-top: 50px;
    }

    h2 {
        padding: 0 0 47px 0;
        text-align: center;

        @media (max-width: $screen-sm) {
            padding: 0 0 20px 0;
        }
    }

    ul {
        li {
            @media (max-width: $screen-xs) {
                width: 100%;
                float: none;
                display: block;
            }
        }
    }
}

.rw_grid {
    padding: 0;
    margin: 0 -9px;
    
    li {
        list-style: none;
        padding: 9px;
    }
}

.details {
    text-align: center;

    span {
        color: #616161;
        padding: 0 0 22px 0;
        display: inline-block;
        @include transition_effect;
    }

    h5 {
        padding: 0 0 25px 0;
        @include transition_effect;
    }

    .details-img {
        background-size: cover;
        background-position: center;
        height: 355px;
        width: 100%;
    }

    .social {
        margin: 0;
        li {
            padding: 0 17px;
            a {}
            i {
                font-size: 24px;
                color: $color_blue;
                @include transition_effect;
            }
        }
    }

    &.adjst1 {

        @media (max-width: $screen-xs) {
            height: inherit !important;
        }
        
        h5 {
            padding: 0 0 16px 0;
            line-height: 38px;
            @media (max-width: $screen-sm) {
                line-height: 32px;
            }
            @media (max-width: $screen-xs) {
                line-height: 28px;
            }
        
        }
        .details_info {
            padding-top: 32px;

            @media(max-width: $screen-xs) {
                padding-bottom: 10px;
            }
        }

    }

    &.adjst2 {
        @media (max-width: $screen-xs) {
            height: inherit !important;
        }
    }
}

.locations {}

.locations_in {
    padding: 90px 18px 30px 18px;
    ul {
        li {
            @media (max-width: $screen-xs) {
                width: 100%;
                display: block;
                float: none;
            }
        }
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
    }

    h2 {
        padding: 0 0 43px 0;
        text-align: center;

        @media (max-width: $screen-sm) {
            padding: 0 0 20px 0;
        }
    }
}

.location_cnt {
    padding: 0 0 28px 0;

    h5 {
        text-align: center;
    }

    figure {
        padding: 0 0 30px 0;
    }

    @media (max-width: $screen-xs) {
        height: inherit !important;
    }
}