.about {}

.about_in {
    padding: 96px 0 70px 0;

    @media(max-width: $screen-md) {
        padding-left: 18px;
        padding-right: 18px;
    }

    @media(max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    ul {
        list-style: none;

        li {
            list-style: none;

            @media(max-width: $screen-xs) {
                width: 100%;
                float: none;
                display: block;
            }
        }
    }

    h1 {
        padding: 0 0 47px 0;
        text-align: center;
        margin: 0;
        font-family: $font-family-secondary;
        font-size: 37px;
        line-height: 47px;
        font-weight: 500;
        color: $color_blk_thick;
        letter-spacing: 0.03em;

        @media(max-width: $screen-sm) {
            font-size: 30px;
            line-height: 36px;
        }
        @media(max-width: $screen-xs) {
            font-size: 26px;
            line-height: 36px;
        }
        @media(max-width: $screen-xs) {
            font-size: 22px;
            line-height: 32px;
        }
    }
}

.about_blk {
    position: relative;
    max-width: 240px;
    margin: 0 auto;
    text-align: center;

    h3 {
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        color: $color_grey_thick3;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    @media(max-width: $screen-xs) {
        height: inherit !important;
    }

    figure {
        padding: 0 0 37px 0;
        margin: 0;

        img {
            vertical-align: middle;
            display: inline;
            max-width: auto;
        }

        @media (max-width: $screen-xs) {
            padding: 0 0 18px 0;
        }
    }

    span {
        padding: 0 0 15px 0;
        display: inline-block;
    }

    @media (max-width: $screen-xs) {

        p {
            padding: 0 0 30px;
        }
    }
}


/*-- gallery --*/

.gallery {}

figure {
    margin: 0;
}

.gallery_in {
    padding: 8px 18px;

    ul {
        margin: 0 -9px;
        list-style: none;
        padding: 0;
    }

    li {
        list-style: none;
        padding: 9px;

        &:nth-child(1) {
            @extend .col-sm-6;
        }

        &:nth-child(2) {
            @extend .col-sm-6;
        }

        &:nth-child(3) {
            @extend .col-sm-3;
        }

        &:nth-child(4) {
            @extend .col-sm-3;
        }

        &:nth-child(5) {
            @extend .col-sm-6;
        }

        &:nth-child(6) {
            @extend .col-sm-6;
        }

        &:nth-child(7) {
            @extend .col-sm-3;
        }

        &:nth-child(8) {
            @extend .col-sm-3;
        }

        @media (max-width: $screen-sm) {

            &:nth-child(3) {
                width: 50%;
            }

            &:nth-child(4) {
                width: 50%;
            }

            &:nth-child(7) {
                width: 50%;
            }

            &:nth-child(8) {
                width: 50%;
            }
        }

        @media(max-width: $screen-xs) {
            width: 100%;
            float: none;
            display: block;

            &:nth-child(3) {
                width: 100%;
            }

            &:nth-child(4) {
                width: 100%;
            }

            &:nth-child(7) {
                width: 100%;
            }

            &:nth-child(8) {
                width: 100%;
            }
        }
    }

    @media (max-width: $screen-xs) {
        padding: 11px 18px;
    }
}

.gallery_blk {
    background-size: cover;
    background-position: center;
    height: 328px;
    width: 100%;
    position: relative;

    figure {
        margin: 0;
    }

    &:hover {
        .about_hov_cnt {
            opacity: 1;
            visibility: visible;
        }
    }
}

.about_hov_cnt {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 254, 0.7);
    opacity: 0;
    visibility: hidden;
    @include transition_effect;

    span {
        padding: 0 0 20px 0;
        display: inline-block;
        font-weight: 500;
        letter-spacing: 0.1em;
        @media(max-width: $screen-sm) {
            padding: 0 0 10px 0;
        }
    }

    h5 {
        padding: 0 0 20px 0;
        line-height: 36px;
        color: $color_white;
        @media(max-width: $screen-sm) {
            padding: 0 0 10px 0;
        }
    }

    .txt_sec {
        color: $color_white;
    }

    .txt_ter {
        color: $color_white;
        line-height: 24px;
    }
}


/*---------blog ---------*/

.blog {}

.blog_in {
    padding: 77px 18px 15px 18px;
    text-align: center;

    @media(max-width: $screen-sm){
        padding-top: 50px;
    }
    
    span {
        padding: 0 0 23px 0;
        display: inline-block;
    
    }

    h2 {}
    
    ul {
        padding: 35px 0 0 0;
        margin: 0 -9px;
        list-style: none;

        @media(max-width: $screen-sm) {
            padding-top: 10px;
        }

        li {
            padding: 9px;
            list-style: none;

            @media(max-width: $screen-xs) {
                float: none;
                display: block;
                width: 100%;
            }
        }
    }
}