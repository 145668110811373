//
// Breadcrumbs
//
@if $generate-bct-classes == true {
	/* Breadcrumbs */
	.bct { @extend %bct; }
}
%bct {
	ul {
		@include list-unstyled();
		padding: $bct-padding-y 0;
		margin: 0;
		li {
			display: inline-block;
			&:before {
				display: inline-block;
				content: $bct-divider;
				margin-right: $breadcrumb-padding-x;
			}
			&:first-child:before { content: none; }
			&:not(:last-child) {
				margin-right: $breadcrumb-padding-x;
			}
		}
	}
}