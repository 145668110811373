//
/* Grids and Containers */
//
// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
%container,
.container {
	@include make-container();
	@include make-container-max-widths();
}
@if ($is-fluid == true) {
	%container,
	.container {
		@include make-container();
		max-width: $screen-lg;
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.
%container-fluid,
.container-fluid {
	@include make-container();
}

// Fluid wrapper with max width
// Reguardless of $is-fluid status, wrapper will always be fluid
%wrapper,
.wrapper {
	@include make-container();
	max-width: $screen-lg;
}

// Row container
// Set with negative margins to accomodate gutter padding
%row,
.row {
	@include make-row();
}

// Create column grid classes
@include make-grid-columns();
