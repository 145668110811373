//
// Print Utilities
//
@if $enable-utility-print == true {
	/* Print Visibility */
	.visible-print-block        { @extend %visible-print-block; }
	.visible-print-inline       { @extend %visible-print-inline }
	.visible-print-inline-block { @extend %visible-print-inline-block; }
	.hidden-print               { @extend %hidden-print; }
}
%visible-print-block {
	display: none !important;
	@media print {
		display: block !important;
	}
}
%visible-print-inline {
	display: none !important;
	@media print {
		display: inline !important;
	}
}
%visible-print-inline-block {
	display: none !important;
	@media print {
		display: inline-block !important;
	}
}
%hidden-print {
	@media print {
		display: none !important;
	}
}