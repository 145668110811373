// Framework grid generation
//
// Used only by Hydrogen to generate the correct number of grid classes given
// any value of `$grid-columns`. If $generate-grid-classes = false then only
// sass inheritence classes will be generated given the same naming convention
// as standard bootstrap grid classes.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-padding, $breakpoints: $grid-breakpoints) {
	// Common properties for all breakpoints
	%grid-columns {
		@include grid-column($gutter);
	}
	$breakpoint-counter: 0;
    @each $breakpoint in map-keys($breakpoints) {
	    $breakpoint-counter: ($breakpoint-counter + 1);

	    @for $i from 1 through $columns {
	    	@if $generate-grid-classes == true {
		        .col-#{$breakpoint}-#{$i} {
		            @extend %grid-columns;
		        }
		    }
	    	%col-#{$breakpoint}-#{$i} {
	            @extend %grid-columns;
	        }
	    }
	    @include breakpoint-up($breakpoint, $breakpoints) {
	        @for $i from 1 through $columns {
	        	@if $generate-grid-classes == true {
		            .col-#{$breakpoint}-#{$i} {
		                @include make-col($i, $columns);
		            }
		        }
	        	%col-#{$breakpoint}-#{$i} {
	                @include make-col($i, $columns);
	            }
	        }
	        @each $modifier in (pull, push) {
	            @for $i from 0 through $columns {
	            	@if $generate-grid-classes == true {
			            .#{$modifier}-#{$breakpoint}-#{$i} {
			                  @include make-col-modifier($modifier, $i, $columns);
			            }
			        }
		        	%#{$modifier}-#{$breakpoint}-#{$i} {
		                  @include make-col-modifier($modifier, $i, $columns);
		            }
	            }
	        }
	        // `$columns - 1` because offsetting by the width of an entire row isn't possible
	        @for $i from 0 through ($columns - 1) {
	            @if $breakpoint-counter != 1 or $i != 0 { // Avoid emitting useless .offset-xs-0
	            	@if $generate-grid-classes == true {
		            	.offset-#{$breakpoint}-#{$i} {
		                	@include make-col-modifier(offset, $i, $columns);
		            	}
		            }
	            	%offset-#{$breakpoint}-#{$i} {
	                	@include make-col-modifier(offset, $i, $columns);
	            	}
	            }
	        }
	    }
    }
}