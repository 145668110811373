//
// Clearfix and Clear Floats Utilities
//
@if $enable-utility-clear == true {
	/* Clearfix and Clear Floats */
	.clearfix    { @extend %clearfix; }
	.clear       { @extend %clear; }
	.clear-left  { @extend %clear-left; }
	.clear-right { @extend %clear-right; }
}
%clearfix {
	@include clearfix();
}
%clear {
	clear: both;
}
%clear-left {
	clear: left;
}
%clear-right {
	clear: right;
}