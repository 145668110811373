// Typography
// 
// Ordered List colored numbers
@mixin orderedlistcolor($color) {
	counter-reset: li; // Initiate a counter
	li {
		position: relative; // Create a positioning context
		padding: 0; // Add some spacing around the content
		list-style: none; // Disable the normal item numbering
	}
	li:before {
		color: $color;
		content: counter(li)'.'; // Use the counter as content
		counter-increment: li; // Increment the counter by 1
		position: relative;
		left: -10px;
		margin-left: -10px;
	}
}

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
	list-style-image: none;
}


// Text hide
@mixin text-hide() {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

// Text truncate
// Requires inline-block or block for proper styling
@mixin text-truncate() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


// Text emphasis
@mixin text-emphasis-variant($parent, $color) {
	#{$parent} {
		color: $color !important;
	}
	a#{$parent} {
		&:hover,
		&:focus {
		  	color: darken($color, 10%);
		}
	}
}