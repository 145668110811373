/* Subpages */

.btn_inv {
    background: rgba(0, 0, 253, 0.7);
    border-color: transparent;
}


/* Teams */

.hov {
    &:hover {
        span {
            color: $color_white;
        }
        h5 {
            color: $color_white;
        }
        .details_info {
            background: #0400fe;
        }
        .social {
            li {
                i {
                    color: $color_white;
                }
            }
        }
    }
}

.details_info {
    @include transition_effect;
    padding: 28px 15px 40px 15px;
}

/* Investors */

.img-stretch {
    width: 100%;
    height: auto;
    display: block;
}

.img-reponsive {
    width: 100%;
    height: auto;
    display: block;
}

.intro {}

.intro_in {
    padding: 110px 18px 70px 18px;
    background: #f6f6f6;
    text-align: center;

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media (max-width: $screen-xs) {}

    h3 {
        color: $color_blk_thick;
        font-family: $font-family-base;
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;
        padding: 0 0 22px 0;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    h1 {
        color: $color_blue3;
        font-family: $font-family-secondary;
        font-size: 37px;
        line-height: 47px;
        font-weight: 500;
        padding: 0 0 30px 0; 
        letter-spacing: 0.03em;
        text-align: center;

        @media (max-width: $screen-sm) {
            font-size: 30px;
            line-height: 36px;
            padding: 0 0 20px 0;
        }

        br {
           @media (max-width: $screen-xs) {
                display: none;
            }
        }
    }

    p {
        padding: 0 0 90px 0;
        @media (max-width: $screen-sm) {
            padding: 0 0 30px 0;
        }
    }

    &.adjst5 {
        padding-top: 98px;
        padding-bottom: 87px;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;

        @media (max-width: $screen-md) {
            padding-left: 18px;
            padding-right: 18px;
        }

        @media (max-width: $screen-sm) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}

.intro_cnt_blk {
    margin: 0 -9px; 

    .eh1 {

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 528px;
        width: 100%;

        @media (max-width: $screen-xs) {
            height: inherit !important;
        }
    }

    div {
        padding: 9px;

        .intro_cnt {
            padding: 60px 15px 15px 15px;
            max-width: 500px;
            margin: 0 auto;
            text-align: left;

            p {
                padding: 0 0 45px;
            }
        }

        @media (max-width: $screen-xs) {
            float: none !important;

            .intro_cnt {
                padding: 30px 15px 15px 15px;
            }
        }
    }

    &.adjst5 {
        padding: 0 0 75px 0;

        @media (max-width: $screen-xs) {
            padding: 0 0 0 0;
        }
    }
    
    div {
        padding: 9px;

        ul {
            padding: 10px 0 0 15px;


            li {
                list-style: none;
                padding: 0 0 9px 23px;
                font-family: $font-family-base;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                color: $color_grey_thick;
                background: url('../graphics/dot.png') no-repeat 10px 8px;
                text-align: left;

                @media (max-width: $screen-xs) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

        }

        ol {
            padding: 10px 0 0 15px;
            list-style: none;

            li {
                counter-increment: count-me;
                padding: 0 0 9px 23px;
                font-family: $font-family-base;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                color: $color_grey_thick;
                text-align: left;
            }

            li::before {
                content: counter(count-me) ". ";
                color: $bg_blue;
                position: relative;
                right: 8px;
            }
        }

        @media (max-width: $screen-xs) {
            float: none !important;
        }
    }

    &.adjst5 {
        padding: 0 0 75px 0;
        @media (max-width: $screen-xs){
            padding: 0 0 0 0;
        }
    }
}

.intro_lt {}

.intro_rt {}

.rt_blk {
    background: $color_white;
}

.intro_cnt {
    padding: 90px 15px 15px 15px;
    max-width: 500px;
    margin: 0 auto;
    text-align: left;

    @media (max-width: $screen-md) {
        padding-top: 45px;
    }

    @media (max-width: $screen-xs) {
        padding-bottom: 45px;
        padding-top: 30px; 
    }

    h2 {
        padding: 0 0 10px 0;
        margin: 0;
        font-family: $font-family-secondary;
        font-size: 24px;
        line-height: 26px;
        font-weight: 500;
        color: $color_blue3;
        letter-spacing: 1px;
    }

    h3 {
        padding: 0 0 10px 0;
        margin: 0;
        font-family: $font-family-secondary;
        font-size: 28px;
        line-height: 30px;
        font-weight: 500;
        color: $color_blue3;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $screen-xs) {
            font-size: 22px;
            line-height: 24px;
        }
    } 

    .txt_lst {
        padding-top: 10px;
    }

    &.adjst5 {
        padding-top: 48px;
    }
}

.txt_lst {
    padding: 0 0 0 15px;

    li {
        padding: 0 0 9px 23px;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: $color_grey_thick;
        background: url('../graphics/dot.png') no-repeat 10px 8px;
        text-align: left;

        @media (max-width: $screen-xs) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}


/*-- cases section --*/

.cases {}

.cases_in {
    padding: 110px 0 77px 0;
    overflow: hidden;

    @media (max-width: $screen-md) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    h2 {
        padding: 0 0 50px 0;
        @media (max-width: $screen-sm) {
            padding: 0 0 20px 0;
        }
    }
}

.cases_cnt_blk {
    padding: 10px 0 10px 0;
    margin: 0 -9px;
    >li {
        padding: 7px 7px;
    }
}

.case_cnt {

    @media (max-width: $screen-xs) {
        padding: 0 0 30px 0;
    }

    h5 {
        padding: 0 0 25px 0;
    }

    ul {
        padding: 0 0 30px 0;

        li {
            list-style: none;
            padding: 2px;
        }
    }

    figure {
        margin: 0 0 22px 0;
        background-size: cover;
        width: 100%;
        height: 192px;
        background-position: center;
    }

    p {
        padding: 0 35px 25px 35px;

        @media (max-width: $screen-sm) {
            padding: 0 0 15px 0;
        }
    }
}

/*-- teams --*/

.teams {}

.teams_in {
    
    padding: 74px 18px 18px 18px;
    text-align: center;
    
    @media (max-width: $screen-sm) {
        padding-top: 40px;
    }

    h2 {
        padding: 0 0 50px 0;
        @media (max-width: $screen-sm) {
            padding: 0 0 30px 0;
        }
    }

    ul {
        > li {
            @media (max-width: $screen-xs) {
                width: 100%;
                float: none;
                display: block;
            }
        }
    }
}

.team_rw {
    margin: 0 -9px;
    
    li {
        padding: 9px 9px;
    }
}

.details {

    figure {
        background-size: cover;
        background-position: center;
        height: 450px;
        width: 100%;

        @media (max-width: $screen-sm) {
            height: 225px;
        }

        @media (max-width: $screen-xs) {
            height: 325px;
        }
    }
    
    span {
        padding: 0 0 22px 0;
        display: inline-block;
        @include transition_effect;
    }

    h5 {
        padding: 0 0 25px 0;
        @include transition_effect;
    }

    .social {
        margin: 0;

        li {
            padding: 0 17px;
            a {}
            i {
                font-size: 24px;
                color: $color_blue;
                @include transition_effect;
            }
        }
    }

    &.adjst1 {

        @media (max-width: $screen-xs) {
            height: inherit !important;
        }

        h5 {
            padding: 0 0 16px 0;
            line-height: 38px;

            @media (max-width: $screen-sm){
                line-height: 32px;
            }

            @media (max-width: $screen-xs) {
                line-height: 28px;
            }
        }

        .details_info {
            padding-top: 32px;
        }
    }

    &.adjst2 {

        @media (max-width: $screen-xs) {
            height: inherit !important;
        }
    }
}

.hov {
    &:hover {
        span {
            color: $color_white;
        }
        h5 {
            color: $color_white;
        }
        .details_info {
            background: #0400fe;
        }
        .social {
            li {
                i {
                    color: $color_white;
                }
            }
        }
    }
}

.details_info {
    @include transition_effect;
    padding: 28px 15px 40px 15px;
}

/* Profiles Detail */

.profile_sec {}

.profile_sec_in {
    padding: 73px 0 102px 0;
    overflow: hidden;

    @media (max-width: $screen-md) {
        padding-left: 18px;
        padding-right: 18px;
    }

    @media (max-width: $screen-sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    ul {
        margin: 0;
    }

    figure {

        img {
            width: 100%;
        }
    }
}

.profile_data {
    padding: 43px 0 0 0;
    
    span {
        padding: 0 0 25px 0;
        display: inline-block;
    }

    h5 {
        padding: 0 0 42px 45px;
        background: url('../graphics/effestephe.png') no-repeat;
        line-height: 34px;
        letter-spacing: none;
        font-weight: 400;
    }

    .social_icons {
        padding: 0 0 40px 0;
        text-align: left;
        
        li {
            padding: 0 30px 0 0;
        }
    }
}

// Search results page
.search_results {

    h3 {
        color: $color_blue3;
        font-weight: 500;
    }

    p {
        padding: 0 0 5px;
        line-height: 24px;

        a {
            color: #747474;
        }
    }

    .property_data {
        padding: 15px 0;
        a {
            color: #747474;
        }
    }
}







