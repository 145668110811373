//
// Images
//
@if $enable-utility-images == true {
	/* Images */
	.img-circle { @extend %img-circle; }
	.figure{ @extend %figure; }
	.figure-img { @extend %figure-img; }
	.figure-caption { @extend %figure-caption; }
} 
%img-circle { border-radius: 50%; }
// Figures
//
%figure {
	// Ensures the caption's text aligns with the image.
	display: inline-block;
}
%figure-img {
	margin-bottom: ($spacer-y / 2);
	line-height: 1;
}
%figure-caption {
	font-size: $figure-caption-font-size;
	color: $figure-caption-color;
}
