/* Globals */
@import 'font-awesome/scss/font-awesome.scss';

html {
	// Remove if not using rem values. 
	// Else change breakpoint or value as needed for desired rem scaling.
	@include breakpoint-down(sm) { font-size: 14px; } // Pixel value only
	height: 100%;
}

// body {
// 	padding-top: 110px; // Offset height of fixed header. Change/remove as needed.
// 	@include breakpoint-up(sm) { padding-top: 115px; }
// }

/* Layout */

.no_bg { 
    background:none !important;
}

.wrapper {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
}

.container {
    @media(max-width: $screen-md) {
        width: 100%;
    }
}



.sticky {
    header {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        background: #ffffff;
        bottom: auto;
    }
    .wrapper-cr8 {
        padding-top: 114px;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;


        @media (max-width: $screen-xs) {
            padding-top: 104px;
        }
    }
}

// FONTS

h1 {
    padding: 0;
    margin: 0;
    font-family: $font-family-secondary;
    font-size: 66px;
    line-height: 68px;
    font-weight: 600;
    color: $color_white;
    letter-spacing: 3px;
    @media (max-width: $screen-xs) {
        font-size: 40px;
        line-height: 46px;
    }
}

.h1_sec {
    font-size: 58px;
    line-height: 68px;
    letter-spacing: 2px;
}

h2 {
    padding: 0 0 15px 0;
    margin: 0;
    font-family: $font-family-secondary;
    font-size: 37px;
    line-height: 47px;
    font-weight: 500;
    color: $color_blk_thick;
    letter-spacing: 1px;
    @media (max-width: $screen-sm) {
        font-size: 30px;
        line-height: 40px;
    }
    @media (max-width: $screen-xs) {
        font-size: 26px;
        line-height: 36px;
    }
    @media (max-width:479px) {
        font-size: 22px;
        line-height: 32px;
    }
}

h3 {
    padding: 0 0 10px 0;
    margin: 0;
    font-family: $font-family-secondary;
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
    color: $color_white;
    
    @media (max-width: $screen-sm) {
        font-size: 26px;
        line-height: 34px;
    }

    @media (max-width: $screen-xs) {
        font-size: 24px;
        line-height: 32px;
    }
}

h4 {
    padding: 0 0 10px 0;
    margin: 0;
    font-family: $font-family-secondary;
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    color: $color_blk_thick;
    @media(max-width: $screen-xs) {
        font-size: 22px;
        line-height: 24px;
    }
}

h5 {
    padding: 0 0 10px 0;
    margin: 0;
    font-family: $font-family-secondary;
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    color: $color_blk_thick;
    letter-spacing: 1px;
    @media(max-width: $screen-sm) {
        font-size: 20px;
        line-height: 24px;
    }
    @media(max-width: $screen-xs) {
        font-size: 18px;
        line-height: 20px;
    }
}

h6 {
    padding: 0;
    margin: 0;
    font-family: $font-family-base;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    color: $color_blk_thick;
}

p {
    padding: 0 0 15px 0;
    margin: 0;
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $color_grey_thick;
    @media(max-width: $screen-xs) {
        font-size: 14px;
        line-height: 22px;
    }
}

a {
    color: $color_black;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $color_black;
        text-decoration: none;
    }
}

.color_blk {
    color: $color_black;
}

.color_white {
    color: $color_white;
}

.color_grey {
    color: $color_grey_thick;
}

.color_grey_light {
    color: $color_grey_thick4;
}

.txt_prime {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #616161;
}

.txt_sec {
    font-family: $font-family-base;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: $color_grey_thick3;
    letter-spacing: 1px;
}

.txt_ter {
    font-family: $font-family-base;
    font-size: 17px;
    line-height: 19px;
    font-weight: 600;
    color: $color_blk_thick;
}

.txt_lst {
    padding: 0 0 0 15px;
    li {
        padding: 0 0 9px 23px;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: $color_grey_thick;
        background: url('../graphics/dot.png') no-repeat 10px 8px;
        @media(max-width: $screen-xs) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.ftw_regular {
    font-weight: 400;
}

.ftw_medium {
    font-weight: 500;
}

.ltr_space1 {
    letter-spacing: 1px;
}

.ltr_space2 {
    letter-spacing: 2px;
}


.tbl {
    display: table;
    width: 100%;
    height: 100%; 
}

.tbl_cell {
    display: table-cell;
    height: 100%;
}

.v_aln_mid {
    vertical-align: middle;
}

.v_aln_bot {
    vertical-align: bottom;
}

.transit-effect {
    transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
}

.bdr-rds {
    border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.bx-shadow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.img-stretch {
    width: 100%;
    height: auto;
    display: block;
}

.img-reponsive {
    width: 100%;
    height: auto;
    display: block;
}

.v_aln_mid {
    vertical-align: middle;
    text-align: center;
}



.transit-effect {
    transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    -ms-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    -webkit-transition: all ease-in-out 0.5s;
}

.bdr-rds {
    border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.bx-shadow {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.img-stretch {
    width: 100%;
    height: auto;
    display: block;
}

.bg_grey {
    background: #f6f6f6;
}

.btn_sec {
    padding: 10px 30px;
    border: 2px solid $color_blue3;
    text-transform: uppercase;
    color: $color_white;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-family-base;
    border-radius: 0px;
    @include transition_effect;
    &:hover,
    &:focus {
        color: $color_white;
        background: $bg_blue;
    }
}

// Page Security Login

.fancybox-wrap {
    width: 475px !important;
}

.fancybox-inner {   
    width: 100% !important;
}

#loginbox {
    width: 100% !important;

    a {
        font-size: 14px !important;
        display: none !important; 
    }
}

.lgnbx {

    h1 {

    }

    .lgnbx_body {

        ul {

            li {
                margin: 0 0 10px; 

                span {
                    font-size: 16px;
                    margin-right: 30px;
                }
            }
        }

        input {
            height: 40px;
            font-size: 16px;
            width: 250px;
        }

        input[type=button]{
            border: none;
            color: $color_white;
            background: $color_blue3;
            float: right; 
            font-size: 15px;
            text-transform: uppercase;
            width: 175px;
        }
    }
}

    // Fancybox styles
.subpage {
    .newsletterv3_complete {
        text-align: center;
        line-height: 1.5;
    }
}

.subpage_popup {
    .newsletterv3_confirm {
        text-align: center;
        line-height: 1.15;

        .btn-default {
            @extend .btn-primary;
            padding: 10px 25px 8px;
        }
    }
}

// 2. Fancybox
//
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
}

.fancybox-opened {
    z-index: 8030;
}

.fancybox-opened .fancybox-skin {
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
    position: relative;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    color: #444;
    font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin: 0;
    padding: 15px;
    white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url('/3rdparty/scripts/fancybox/2.1.5/img/fancybox_sprite.png');
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('/3rdparty/scripts/fancybox/2.1.5/img/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}

.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    background: transparent url('/3rdparty/scripts/fancybox/2.1.5/img/img/blank.gif'); /* helps IE */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 8040;
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: url('/3rdparty/scripts/fancybox/2.1.5/img/fancybox_overlay.png');
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center;
}

.fancybox-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5){

    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background-image: url('/3rdparty/scripts/fancybox/2.1.5/img/fancybox_sprite@2x.png');
        background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }

    #fancybox-loading div {
        background-image: url('/3rdparty/scripts/fancybox/2.1.5/img/fancybox_loading@2x.gif');
        background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
    }
}

.nl-header h3 {
    color: #000;
}

