//
// Screenreader Utilities
//
@if $enable-utility-sr == true {
	/* Screenreaders */
	.sr-only           { @extend %sr-only; }
	.sr-only-focusable { @extend %sr-only-focusable; }
}
%sr-only {
	@include sr-only();
}
%sr-only-focusable {
	@include sr-only-focusable();
}