//
// Bars, Navigation and Dropdowns
//
@if $generate-nav-classes == true {
	/* Bars, Navigation and Dropdowns */
	.bar              { @extend %bar; }
	.bar-fixed-top    { @extend %bar-fixed-top; }
	.bar-fixed-bottom { @extend %bar-fixed-bottom; }
	.bar-sticky-top   { @extend %bar-sticky-top; }
	.nav              { @extend %nav; }
	.nav-dropdown     { @extend %nav-dropdown; }
	.dropdown         { @extend %dropdown; }
}
// Basic Bar
%bar {
	background: $bar-bg;
	font-family: $bar-font;
	font-size: $bar-font-size;
	position: relative;
	padding: $bar-padding;
	z-index: $zindex-bar;
	@include clearfix;
}

// Bar alignment options
//
// Display a bar fixed it to the top or
// bottom of the page.
%bar-fixed-top,
%bar-fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	z-index: $zindex-bar-fixed;
}
%bar-fixed-top {
	top: 0;
}
%bar-fixed-bottom {
	bottom: 0;
}
%bar-sticky-top {
	position: sticky;
	top: 0;
	z-index: $zindex-bar-sticky;
	width: 100%;
}

// Navigation List
//
%nav {
	padding: $nav-padding;
    margin: $nav-margin;
    list-style: none;
    @include clearfix();
	> li {
		float: left;
		position: relative;
		display: block;
		padding: $nav-item-padding;
		&:hover {
			> a { 
				background-color: $nav-link-hover-bg; 
				color: $nav-link-hover-color;
			}
		}
		> a {
			display: block;
			padding: $nav-link-padding;
			background-color: $nav-link-bg; 
			color: $nav-link-color;
		}
	}
}
%nav-dropdown {
	> li {
		&:hover {
			> ul { 
				max-height: 100vh;
				opacity: 1;
				@if $enable-transitions ==  true {
					transition: all 0.3s ease-in-out 0s;
				}
			}
		}
		> ul {
			@extend %dropdown;
		}
	}
}
// Dropdowns
%dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: $dropdown-min-width;
	padding: $dropdown-padding;
	background: $dropdown-bg;
	border: $dropdown-border;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	z-index: $zindex-dropdown;
	@if $enable-transitions ==  true {
		transition: all 0.1s ease-in-out 0s;
	}
	%bar-fixed-bottom & {
		top: auto;
		bottom: 100%;
	}
	li {
		padding: $dropdown-item-padding;
		margin: $dropdown-item-margin;
		&:hover {
			a {
				background-color: $dropdown-link-hover-bg; 
				color: $dropdown-link-hover-color;
			}
		}
		a {
			display: block;
			padding: $dropdown-link-padding;
			background-color: $dropdown-link-bg; 
			color: $dropdown-link-color;
			&.active {
				background-color: $dropdown-link-active-bg; 
				color: $dropdown-link-active-color;
			}
		}
	}
}