//
// Visibility Utility
//
@if $enable-utility-visibility == true {
	/* Visibility */
	.invisible { @extend %invisible; }
}

// Hidden
%invisible {
	visibility: hidden !important;
}

// Responsive visibility utilities
@each $bp in map-keys($grid-breakpoints) {
	@if $enable-utility-visibility == true {
		%hidden-#{$bp}-up,
		.hidden-#{$bp}-up {
			@include breakpoint-up($bp) {
				display: none !important;
			}
		}
		%hidden-#{$bp}-down,
		.hidden-#{$bp}-down {
			@include breakpoint-down($bp) {
				display: none !important;
			}
		}
		%hidden-#{$bp}-only,
		.hidden-#{$bp}-only {
			@include breakpoint-only($bp) {
				display: none !important;
			}
		}
	} @else {
		%hidden-#{$bp}-up {
			@include breakpoint-up($bp) {
				display: none !important;
			}
		}
		%hidden-#{$bp}-down {
			@include breakpoint-down($bp) {
				display: none !important;
			}
		}
		%hidden-#{$bp}-only {
			@include breakpoint-only($bp) {
				display: none !important;
			}
		}
	}
}
