//
// Text Utilities
//
@if $enable-utility-text == true {
	/* Text Styles */
	.text-justify    { @extend %text-justify; }
	.text-nowrap     { @extend %text-nowrap; }
	.text-truncate   { @extend %text-truncate; }
	.text-lowercase  { @extend %text-lowercase; }
	.text-uppercase  { @extend %text-uppercase; }
	.text-capitalize { @extend %text-capitalize; }
	.text-hide       { @extend %text-hide; }

	.text-primary    { @extend %text-primary; }
	.text-secondary  { @extend %text-secondary; }
	.text-tertiary   { @extend %text-tertiary; }
	.text-success    { @extend %text-success; }
	.text-info       { @extend %text-info; }
	.text-warning    { @extend %text-warning; }
	.text-danger     { @extend %text-danger; }
}
// Alignment
%text-justify { text-align: justify !important; }
%text-nowrap { white-space: nowrap !important; }
%text-truncate { @include text-truncate; }

// Hide Text
%text-hide {
	@include text-hide();
}

// Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
	@if $enable-utility-text == true {
		@include breakpoint-up($breakpoint) {
			%text-#{$breakpoint}-left,
			.text-#{$breakpoint}-left   { text-align: left !important; }
			%text-#{$breakpoint}-right,
			.text-#{$breakpoint}-right  { text-align: right !important; }
			%text-#{$breakpoint}-center,
			.text-#{$breakpoint}-center { text-align: center !important; }
		}
	} @else {
		@include breakpoint-up($breakpoint) {
			%text-#{$breakpoint}-left   { text-align: left !important; }
			%text-#{$breakpoint}-right  { text-align: right !important; }
			%text-#{$breakpoint}-center { text-align: center !important; }
		}
	}
}

// Transformation
%text-lowercase { text-transform: lowercase !important; }
%text-uppercase { text-transform: uppercase !important; }
%text-capitalize { text-transform: capitalize !important; }

// Contextual colors
@include text-emphasis-variant('%text-primary', $brand-primary);
@include text-emphasis-variant('%text-secondary', $brand-secondary);
@include text-emphasis-variant('%text-tertiary', $brand-tertiary);
@include text-emphasis-variant('%text-success', $brand-success);
@include text-emphasis-variant('%text-info', $brand-info);
@include text-emphasis-variant('%text-warning', $brand-warning);
@include text-emphasis-variant('%text-danger', $brand-danger);
