/// Grid system mixins
//
// Generate semantic grid columns with these mixins.
@mixin make-container($gutter: $grid-padding) {
	margin-left: auto;
	margin-right: auto;
	padding-left:  $gutter;
	padding-right: $gutter;
	@include clearfix();
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@if ($enable-responsive == true) {
		@each $breakpoint, $container-max-width in $max-widths {
		    @include breakpoint-up($breakpoint, $breakpoints) {
		    	max-width: $container-max-width;
		    }
		}
	} @else {
		max-width: $screen-lg;
	}
}

// Make row wrapper for containers that off-sets gutter
@mixin make-row($gutter: $grid-padding) {
	@include clearfix();
	margin-left:  -$gutter;
	margin-right: -$gutter;
}

// Make columns, determin width of each column
@mixin make-col($size, $columns: $grid-columns) {
    float: left;
    width: percentage($size / $columns);
}

// Generate column off-set
@mixin make-col-offset($size, $columns: $grid-columns) {
	margin-left: percentage($size / $columns);
}

// Generate push offset by column amount
@mixin make-col-push($size, $columns: $grid-columns) {
	left: if($size > 0, percentage($size / $columns), auto);
}

// Generate pull offset by column amount
@mixin make-col-pull($size, $columns: $grid-columns) {
	right: if($size > 0, percentage($size / $columns), auto);
}

// Modifier, cycle through and select offset type
@mixin make-col-modifier($type, $size, $columns) {
	@if $type == push {
	    @include make-col-push($size, $columns);
	} @else if $type == pull {
	    @include make-col-pull($size, $columns);
	} @else if $type == offset {
	    @include make-col-offset($size, $columns);
	}
}

// Common properties for all columns and breakpoints
@mixin grid-column($gutter: $grid-padding) {
	position: relative;
	// Prevent columns from collapsing when empty
	min-height: 1px;
	// Inner gutter via padding
	padding-right: $gutter;
	padding-left: $gutter;
}

// Generate a column
@mixin column($size, $columns, $padding: $grid-padding) {
	// Check values
	@if not index(xs sm md lg, $size) {
        @error "Sorry, but `#{$size}` is not a valid value for $size. Please use xs, sm, md or lg";
    }
    // Styles for all columns
    @include grid-column($padding);

    // By Default be full width
    @include make-col($grid-columns);
	@include breakpoint-up($size) {
		@include make-col($columns);
	}
}

// Generate column limited to single breakpoint
@mixin single-column($size, $columns, $padding: $grid-padding) {
	// Check values
	@if not index(xs sm md lg, $size) {
        @error "Sorry, but `#{$size}` is not a valid value for $size. Please use xs, sm, md or lg";
    }
    // Styles for all columns
    @include grid-column($padding);

    // By Default be full width
    @include make-col($grid-columns);
	@include breakpoint-only($size) {
		@include make-col($columns);
	}
}

// Generate column with multiple breakpoints
@mixin multi-column($xs: null, $sm: null, $md: null, $lg: null, $padding: null) {
	@if $padding == null {
		$padding: $grid-padding;
	}
	// Styles for all columns
	@include grid-column($padding);

	// Variable Sizes
	$column-sizes: (
		xs: $xs,
		sm: $sm,
		md: $md,
		lg: $lg
	);
	@if $xs == null {
		@include make-col($grid-columns);
	} @else if $sm == null {
		@include make-col($xs);
	} @else if $md == null {
		@include make-col($xs);
		@include breakpoint-up(sm) {
			@include make-col($sm);
		}
	} @else if $lg == null {
		@include make-col($xs);
		@include breakpoint-only(sm) {
			@include make-col($sm);
		}
		@include breakpoint-up(md) {
			@include make-col($md);
		}
	} @else {
		@each $column in $column-sizes {
			@include breakpoint-up(#{nth($column, 1)}) {
				@include make-col(nth($column, 2));
			}
		}
	}
}

// Generate column/breakpoint off-sets, pushes and pulls
@mixin position-column($size, $type, $columns) {
	// Check values
	@if not index(xs sm md lg, $size) {
        @error "Sorry, but `#{$size}` is not a valid value for $size. Please use xs, sm, md or lg";
    }
    @if not index(push pull offset, $type) {
        @error "Sorry, but `#{$type}` is not a valid value for $type. Please use push, pull or offset";
    }

    @include breakpoint-up($size) {
    	@include make-col-modifier($type, $columns, $grid-columns);
    }
}
