//
/* Tables */
//
@if $enable-table-styles == true {
	table { @extend %table; }
}
@if $enable-utility-tables == true {
	.table { @extend %table; }
	.table-sm { @extend %table-sm; }
	.table-bordered { @extend %table-bordered; }
	.table-striped { @extend %table-striped; }
	.table-hover { @extend %table-hover; }
	.table-responsive { @extend %table-responsive; }
}

//Default Table
//
%table {
	width: 100%;
	max-width: 100%;
	margin-bottom: $spacer;
	th,
	td {
		padding: $table-cell-padding;
		vertical-align: top;
		border-top: $table-border-width solid $table-border-color;
	}
	thead th {
		vertical-align: bottom;
		border-bottom: (2 * $table-border-width) solid $table-border-color;
	}
	tbody + tbody {
		border-top: (2 * $table-border-width) solid $table-border-color;
	}
	.table {
		background-color: $body-bg;
	}
	@if $enable-table-hover == true {
		@extend %table-hover; 
	}
	@if $enable-table-responsive == true {
		@extend %table-responsive; 
	}
}

// Condensed table w/ half padding
//
%table-sm {
	th,
	td {
		padding: $table-sm-cell-padding;
	}
}

// Bordered version
//
// Add borders all around the table and between all the columns.
%table-bordered {
	border: $table-border-width solid $table-border-color;
	th,
	td {
		border: $table-border-width solid $table-border-color;
	}
	thead {
		th,
		td {
			border-bottom-width: (2 * $table-border-width);
		}
	}
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
%table-striped {
	tbody tr:nth-of-type(odd) {
		background-color: $table-bg-accent;
	}
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping
%table-hover {
	tbody tr {
		&:hover { background-color: $table-bg-hover; }
	}
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.
%table-responsive {
	@media (max-width: $screen-xs-max) {
		display: block;
		width: 100%;
		min-height: .01%;
		overflow-x: auto;
	}
}