//**************************//
//   Default BlogV3 Styles	//
//**************************//

// General layout styles, affecting blog_main.emt, blog_nav.emt, and blog_post.emt
.blog-main-content {
	// @include multi-column(12,12,9);
	// @include position-column(md,push,3);

	padding: 98px 0 87px 0;
}

.social-sharing {
	margin-bottom: 40px;

	p {
		letter-spacing: 0.07em;
	}
}

.blog-sidebar {
	@include multi-column(12,12,3);
	@include position-column(md,pull,9);
}

.post-wrapper {
	margin-bottom: 40px;

	.blog-icon-wrapper {
		// @include multi-column(12,12,2);
	}

	.blog-icon {
		width:183px;
		padding-bottom: 10px; 
	}

	.date,
	.photo {
		display: inline-block;
		height: 90px;
		width:90px;
		text-align: center;
	}

	.date {
		background-color: $brand-primary;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		float: left;
	}

	.date h2 {
		color: #fff;
		line-height: 35px;
		padding-top: 2px;
		margin: 7px auto 0;
		max-width: 70px;
		font-size: 32px;
	}

	.photo {
		float: right;
	}

	.photo img {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		height:90px;
		width:90px;
	}

	.content-header {
		// @include multi-column(12,12,10);
	}

	.content-header p {
		font-size: 14px;
	}

	.content-header h4 a {
		font-family:$headings-font-family;
	}
}

.post-content {
	padding: 40px 0 0 .9375rem;
	clear:both;

	h1 {
		clear: both;
		padding:15px 0;
	}

	a {
		color: #6465ff;
	}
}

.blog-pagination {
	height: 70px;

	div {
		display: inline-block;
	}

	.blog_pages_prevnext {
		width: 50%;
	}

	.blog_pages_select {
		width: 150px;
		float: right;
		line-height: 36px;
	}

	.blog_pages_select select {
		width: 55px;
		float: right;
	}
}

.addthis_button {
	float: right;
}


// COMMENTS & LOGIN SECTION
// Only visible when blog comments are enabled

#commentform {
	input[type='text'],
	textarea {
		background-color: #fff;
		color: #a1a1a1;
		font-size: 14px;
		border: #a1a1a1 solid 1px;
		height: 30px;
		width: 100%;
		padding-left: 10px;
		font-weight: 400;
		margin: 0 0 16px;
	}

	input#email {
		margin-bottom: 0;
	}

	small {
		font-size: 12px;
	}

	textarea {
		height: 76px;
		margin-bottom: 0;

		@media only screen and (max-width: $screen-sm) {
			margin-top: 15px;
		}
	}

	.commenttext, 
	.blog_comment_form_submit, 
	.register_block {
		@include multi-column(12,4);

		@media only screen and (max-width: $screen-sm) {
			padding-left: 0;
			padding-right: 0;
		}

		&.right {
			min-height: 100px;

			@media only screen and (max-width: $screen-sm) {
				float: none;
			}
		}

		&:first-child {
			@media only screen and (min-width: $screen-sm) {
				padding-left: 0;
			}
		}
	}


	.blog_comment_form_submit input {
		margin: 10px 0 0;
		width: 100%;

		@media only screen and (min-width: $screen-sm) {
			font-size: 14px;
			padding: 5px 10px;			
		}
	}

	.small.button {
		margin: 0 14px;
		padding: 10px 35px;
		text-transform: none;
		font-size: 15px;
	}	
}

.blog_comment_form_login,
.blog_comment_form_register {
	@extend .btn-default;
	width: 100%;
}
