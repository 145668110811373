/* Page Header*/

// #page-header {
// 	.logo {
// 		float: left;
// 	}
// 	.search-box {
// 		float: right;
// 		input { display: inline-block; }
// 	}
// }

/* Outsource */

/* mp3 outer template */
.mp3 {
	padding: 0;
}

.banner_full {
    height: 100%;
    padding: 0;

    @media (max-width: $screen-xs){
        height: auto;
    }

    .wrapper-cr8 {
        height: 100%;
    }

    .banner {
        height: 100%;
        padding: 0 0 114px 0;

        @media (max-width: $screen-xs) {
            padding: 0;
        }

        .container-fluid {
            height: 100%;
        }

        .row {
            height: 100%;
        }

        .banner_in {
	        height: 100%;
	        background-size: cover;
            background-position: center;
	        position: relative;

	        @media (max-width: $screen-xs) {
	            background: none;
	        }
	        
	        figure {
	            @media (max-width: $screen-xs) {
	                height: 400px;
	                position: relative;
	                overflow: hidden;
	            }
	            img {
	                @media (max-width: $screen-xs) {
	                    height: 100%;
	                    width: auto;
	                    max-width: none;
	                    position: absolute;
	                    top: 0;
	                    left: 0;
	                }
	            }
	        }
	    }
    }

    header {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        @media (max-width: $screen-xs) {
            position: static;
        }
    }

} // .banner_full (homepage) end

/* Header */

.sticky {
    header {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        background: #ffffff;
        bottom: auto;
    }
    .wrapper {
        padding-top: 114px;
        @media (max-width: $screen-xs) {
            padding-top: 104px;
        }
    }
}

header {
    position: relative;
}

.header_in {
    padding: 26px 0 30px 0;
    overflow: hidden;
    position: relative;
    @media (max-width: $screen-md){
        padding-left: 15px;
        padding-right: 15px;
    }
    @media (max-width: $screen-xs) {
        padding-bottom: 0;
        padding-top: 10px;
    }
    .navbar-header {
        @media (max-width: $screen-xs) {
            position: absolute;
            top: 10px;
            right: 18px;
        }
    }
    .navbar-toggle {
        background: $bg_blue;
        margin-right: 0;
        border-radius: 0;
        border: none;

        .icon-bar {
            background: #fff;
        }

        @media(min-width: $screen-sm) {
        	display: none;
        }
    }
}

.header_tp {
    ul {
    	padding: 0;
    	margin: 0;
        list-style: none;

        @media (max-width: $screen-xs) {
            float: none;
        }
        
        li {
            list-style: none;

            @media (max-width: $screen-xs) {
                padding: 5px 0;
            }
            
            @media (max-width: $screen-xs) {}
            
            a {
                font-family: $font-family-base;
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                color: $color_blk_thick;
                letter-spacing: 0.07em;
            }

            &.c_login {
                a {
                    padding: 0 0 0 30px;
                    display: inline-block;
                    background: url('../graphics/user_icon.png') no-repeat left center;
                }
            }

            &.b_login {
                a {
                    padding: 0 30px 0 0px;
                    display: inline-block;
                    background: url('../graphics/home_icon.png') no-repeat right center;
                }
            }
        }
    }
}

.cust_nav {
    margin: 0;
    padding: 24px 0 0 0;
    border: 0 none;
    min-height: 1px;

    @media(max-width: $screen-xs) {
        padding-top: 10px;
    }

    ul {
        float: left;
        margin: 0 -16px;
        margin-right: 10px;
        padding: 0; 

        @media(max-width: $screen-md)  {
            margin: 0 -13px;
        }

        @media(max-width: $screen-sm) {
            margin: 0 -10px;
        }

        @media(max-width: $screen-xs) {
            margin: 0 0;
            width: 100%;
        }

        li {
            list-style: none;
            float: left;
            padding: 0 16px;
            text-transform: uppercase;

            @media(max-width: $screen-md) {
                padding: 0 13px;
            }

            @media(max-width: $screen-sm) {
                padding: 0 10px;
            }

            @media(max-width: $screen-xs) {
                padding: 0;
                float: none;
                text-align: center;
                padding: 15px 0;
            }

            a {
                padding: 0;
                color: $color_blk_thick;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                font-family: $font-family-base;
                background-color: transparent;
                letter-spacing: 1px;

                @media(max-width: $screen-xs) {
                    font-size: 16px;
                    font-weight: 400;
                    padding: 7px 15px;
                    text-align: center;
                    color: $color_white;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $color_blue3;

                    @media(max-width: $screen-xs) {
                        color: $color_white;
                        background: lighten(#0001fd, 15%)
                    }
                }
            }

            &.active-state {
                a {
                    color: $color_blue3;

                    @media(max-width: $screen-xs) {
                        color: $color_white;
                        background: lighten(#0001fd, 15%);
                    }
                }
            }
        }
    }

    .navbar-nav {
        @media (max-width: $screen-xs) {
            &:first-child {
                ul {
                    padding-top: 20px;
                }
            }

            &:last-child {
                ul {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.logo {
    position: absolute;
    left: 50%;
    top: 38px;
    margin-left: -86px;
    z-index: 1;

    @media(max-width: $screen-sm){
        top: 7px;
    }

    @media(max-width: $screen-xs) {
        position: static;
        display: block;
        text-align: left;
        margin: 0;
    }
}

.nav_links {

    @media(min-width: $screen-sm) {
        display: block;

        .navbar-nav {
        	float: left;
        }

        .navbar-right {
        	float: right !important;
        }

        .navbar-right-fa {
            display: block;
            float: right;
        }
    }

    @media(max-width: $screen-xs) {
        margin: 0 -15px;
        background: #0001fd;
        // height: calc(100% - 104px);
        position: fixed;
        width: 100%;
        z-index: 5;
        overflow: auto;
        display: none;

        .navbar-right {
            margin: 0;
        }

        .navbar-right-fa {
            display: none;
        }
    }
}

/* --- search bar --- */

.menu-srch-overlay {
    background-color: rgba(246,246,246, 0.9);
    display: none;
    z-index: 99999;
    width: 100%;
    overflow: hidden;
    opacity: 1;
    transition: opacity, height .3s ease;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    font-family: $font-family-base;
    text-align: center;
    button[type="submit"] { 
        width: auto; 
        vertical-align: middle;
    }

    .nav-search {
        display: block;
        padding: 30px;
    }

    .nav-search-input {
        display: inline-block;
        width: 336px;
        margin: 0 5px;
        max-width: 100%;
        vertical-align: middle;

        @media (max-width: $screen-xs) {
            width: 200px;
        }
    }

    .nav-search-btn {
        color: #fff;
        background: #d95e00;
        border: none;
        padding: 0 10px;
        height: 37px;
        font-size: 14px;
        font-family: $font-family-base;
        text-transform: uppercase;

        &:hover {
            background: #f36900;
        }
    }

}

.close-btn {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    vertical-align: middle;
    &:before {
        position: absolute;
        top: 20px;
        left: 0;
        border-top: 2px solid $gray;
        content: '';
        display: block;
        height: 1px;
        width: 40px;
        transform: rotate(45deg);
    }
    &:after {
        position: absolute;
        top: 20px;
        right: 0;
        border-top: 2px solid $gray;
        content: '';
        display: block;
        height: 1px;
        width: 40px;
        transform: rotate(-45deg);
    }
}

/* --- banner --*/

.banner {
    display: block;
    position: relative;

    h4 {
        padding: 0 0 55px 0;
        margin: 0;
        font-family: $font-family-secondary;
        font-size: 58px;
        line-height: 68px;
        font-weight: 600;
        color: $color_white;
        letter-spacing: 3px;

        @media(max-width: $screen-sm) {
            font-size: 48px;
            line-height: 60px;
            padding: 0 0 25px 0;
        }

        @media(max-width: $screen-xs) {
            font-size: 24px;
            line-height: 30px;
            padding: 0 0 25px 0;
        }
    }
}

.banner_in {
    display: block;
}

.banner_ttl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;

    ul {
        margin: 0;
        li {
            padding: 5px;
        }
    }
}


/* Mobile/Mmenu */

// Menu
//
// 1. Logo within mmenu subpanel
// 2. Style mobile search input in mmenu subpanel
// 3. Override mmenu styles for an inline list of sublinks/items
#mobile-menu {
	.sub-panel-logo { // 1
		img { 
			margin: 0 auto 20px; 
		}
	}
	.sub-panel-search { // 2
		input {
			color: $gray;
			width: 100%;
		}
	}
	.sub-panel-clearlist { // 3
		margin-left: 0;
		margin-right: 0;
		text-align: center;
		li {
			display: inline-block;
			&:after { border: 0; }
			a { 
				padding: 10px;
				&.mm-next { 
					padding: 10px 0;
					width: 30px; 
					&:after {
						right: auto;
						left: 7px;
					}
				}
				&.mm-next+a,
				&.mm-next+span {
					margin-right: 30px;
				}
			}
		}
	}
}

// Menu Toggle
//
// 1. Pixel values only in mixin, no rem. 
//    For use like so: @include burger(bar width, bar height, space between bars, bar color, border-radius, transition-duration)
// 2. This mixin will translate values from burger() mixin into correct "X" active state.
#nav-toggle {
	cursor: pointer;
	padding: rem(15px);
	position: absolute;
	top: 0;
	right: 0;
	a {
		@include burger(35px, 4px, 5px, $brand-primary); // 1
		display: block;
	}
	&.is-active a {
		@include burger-to-cross; // 2
	}
}

// Basic Mmenu Overrides
//
// 1. If not setting a navbar -> title (check mmenu navbar settings in router.js) then remove padding from first panel
// 2. These variables are limited to use within .mm-menu class nesting
#mm-0 { padding-top: 0; } // 1
.mm-menu {
	// Mmenu variables
	$mm-bg: $brand-primary; // 2
	$mm-color: #fff;
	$mm-border: rgba(0,0,0,0.1);

	background: $mm-bg;
    border-color: $mm-border;
    color: $mm-color;

    // Navbar Styles
    .mm-navbar {
    	a,
    	> * { color: $mm-color; }
    }

    // List Styles
    .mm-listview {
    	border-color: $mm-border;
    	font-size: rem(16px);
    	font-family: inherit;
    	> li {
    		a:hover {
    			background-color: darken($mm-bg, 5%);
    		}
    		.mm-next {
    			border-color: transparent;
    			&:after {
    				border-color: $mm-color;
    			}
    			&:hover {
    				background-color: $mm-border;
    			}
    		}
    	}
    }

    // Buttons Styles
    .mm-btn {
    	&:before,
    	&:after {
    		border-color: $mm-color;
    	}
    }
}