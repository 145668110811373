/* Page Footer */

footer {}

.footer_in {
    padding: 60px 18px 35px 18px;
 }

.sign_sec {
    max-width: 830px;
    margin: 0 auto;
    
    @media(max-width: $screen-sm) {
        text-align: center;
    }

    .txt_prime {
        padding: 0 0 10px 0;
        display: inline-block;
        font-weight: 600;
        text-align: center;
    }
    
    .eml {
        width: 203px;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
    }
    
    .slt {
        width: 193px;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        
        @media(max-width: $screen-xs) {
            width: 100%;
        }
    }

    .form-group {
    	display: inline-block;
    	margin: 0;
    	vertical-align: middle;

        @media(max-width: $screen-xs) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .form-control {
        padding-left: 20px;
        width: 100%;
        background: #f4f4f4;
        border-radius: 0px;
        height: 40px;
        font-family: $font-family-base;
        font-size: $font-size-root;
        font-weight: 600;
        color: $color_grey_thick2;
        border: 0 none;
        outline: 0 none;
        box-shadow: none;
    }

    ul {
        margin: 0;
        display: inline-block;
        
        @media(max-width: $screen-xs) {
            display: block;
            margin: 0;
            padding: 0;
        }

        li {
            padding: 0 3px;
        }
    }
}

.cust_select {
    width: 100% !important;

    .btn {
        padding-left: 20px;
        height: 40px;
        background: #f4f4f4;
        border-radius: 0;
        border: 0 none;
        box-shadow: none;
        outline: 0 none;

        &:focus {
            outline: 0 none !important;
            box-shadow: none;
        }

        &:hover {
            box-shadow: none;
        }
    }

    .filter-option {
        font-family: $font-family-base;
        font-size: $font-size-root;
        font-weight: 600;
        color: $color_grey_thick2;
    }

    .bs-caret {
        .caret {
            border: 0 none;
            
            &:after {
                position: absolute;
                top: 3px;
                right: 0px;
                background: #f00;
                z-index: 1;
                content: "\f107";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                color: #0001fd;
                font-size: 18px;
                line-height: 0;
            }
        }
    }
}

.btn_sigup {
    padding: 11px 34px;
    background: #0001fd;
    border: none;
    font-family: $font-family-base;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    color: $color_white;
    text-transform: uppercase;
    border-radius: 0px;

    @include transition_effect;
    
    &:hover,
    &:focus {
        color: $color_white;
        background: lighten(#0001fd, 20%);
    }

    @media (max-width: $screen-xs) {
        width: 100%;
    }
}

.reset {
    color: #0001fd;
    background: none;
    border: 2px solid #0001fd;
    padding: 9px 34px;
}


.footer_cnt_rw {
    padding: 51px 0 47px 0;
    margin: 0 -9px;
    
    @media(max-width: $screen-xs) {
        padding-top: 35px;
    }
    
    li {
        list-style: none;
        padding: 9px;
    }

    .ftr_cnt {
	    position: relative;

	    figure {
	    	margin: 0;

	        @media (max-width: 479px) {
	            height: 250px;
	            position: relative;
	            overflow: hidden;
	        }

	        img {
	            @media (max-width: 479px){
	                position: absolute;
	                top: 0;
	                left: 0;
	                width: auto;
	                height: 100%;
	                max-width: none;
	            }
	        }
	    }

        &:hover {
            background-color: rgba(0,0,254,0.75);
        }

	    .ftr_cnt_mid {
		    padding: 18px;
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;

		    h3 {
		        padding: 0 0 34px 0;
		        letter-spacing: 1px;
		        
		        @media(max-width: $screen-sm) {
		            padding: 0 0 20px 0;
                    font-size: 22px;
                    line-height: 30px;
		        }

		        br {
		            @media(max-width: $screen-sm) {
		                display: none;
		            }
		        }
		    }

            &:hover {
                background-color: rgba(0,0,254,0.50);
                padding: 0;
            }
		}

		.btn_prime {
		    padding: 10px 30px;
		    border: 1px solid #ffffff;
		    text-transform: uppercase;
		    color: $color_white;
		    font-weight: 600;
		    font-size: $font-size-root;
		    line-height: $font-size-root;
		    font-family: $font-family-base;
		    border-radius: 0px;
		    @include transition_effect;
		    &:hover,
		    &:focus {
		        color: $color_white;
		        background: $bg_blue;
		        border-color: transparent;
		    }
		}
	}
}

.social_icons {
    margin: 0;
    padding: 0 0 14px 0;
    list-style: none;
    text-align: center;

    li {
        padding: 0 13px;
        display: inline;
        i {
            font-size: 18px;
            color: #0100fc;
        }
    }
}

.footer-cr {
	color: $brand-primary;
	text-align: center;

	a {
		color: $color_blue3;
	}
}
