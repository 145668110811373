//
// Display Utilities
//
@if $enable-utility-display == true {
	/* Display */
	.d-block        { @extend %d-block; }
	.d-inline-block { @extend %d-inline-block; }
	.d-inline       { @extend %d-inline; }
}
%d-block {
	display: block !important;
}
%d-inline-block {
	display: inline-block !important;
}
%d-inline {
	display: inline !important;
}